import { useRoutes } from "react-router-dom";
import Home from "./components/Home";
import ArticleHomePage from "./components/ArticleHomePage";
import MainLayout from "./layout/MainLayout";
import Product from "./components/Product";

export default function Router() {


    const routes = useRoutes([
        {
            element: <MainLayout />,
            children: [
                {
                    path: "/post/:_id",
                    element: <ArticleHomePage />
                },
                {
                    path: "/*",
                    element: <Home />
                },
                {
                    path: "/products",
                    element: <Product />
                },
            ]

        },
    ]);
    return routes;
}



import pageStyle from "./index.module.scss";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';


export default function HMIOperatorPanel({ listData }) {



    return <div>
        <div className={pageStyle['banner-bg']}
            style={{ backgroundImage: `url(${listData?.banner})` }}
        >
            <div className={pageStyle['title-img']}>{listData?.title}</div>
            <div className={pageStyle['description-img']}>
                {listData?.description}
            </div>
        </div>
        <div style={{ backgroundColor: '#fafafa', padding: '45px 0' }}>
            <div style={{ fontSize: '35px', fontWeight: 'bold', textAlign: 'center', padding: '15px 0' }}>
                {listData?.label}
            </div>
            <div className={pageStyle['container-product']}>
                {listData?.listProduct.map((item, index) => {
                    return <div
                        key={index}
                        style={{ marginTop: '10px' }}
                    >
                        <Card
                            sx={{ maxWidth: 260, borderRadius: 0, boxShadow: 1 }}>
                            <CardActionArea sx={{ height: 375 }}>
                                <CardMedia
                                    component="img"
                                    // maxHeight="240"
                                    image={item?.image}
                                    alt="green iguana"
                                    sx={{ margin: 'auto' }}
                                />
                                <CardContent sx={{ textAlign: 'center', }}>
                                    <Typography variant="body2" color="#a20022">
                                        {item?.description}
                                    </Typography>
                                    <Typography gutterBottom variant="h5" component="div"
                                        sx={{ fontSize: '20px', fontWeight: 'bold', marginTop: '5px' }}
                                    >
                                        {item?.title}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                })}


            </div>
        </div>
        <div className={pageStyle['container-description']}>
            <div className={pageStyle["description-left"]}>
                <div style={{ fontSize: '24px', fontWeight: 'bold', width: '100%' }}>
                    {listData?.about?.title}
                </div>
                <div>
                    {listData?.about?.description}

                </div>
            </div>
            <div className={pageStyle["description-right"]}>
                <img src={listData?.about?.image} alt="img" />
            </div>
        </div>

        <div className={pageStyle['oem-block']}
            style={{ backgroundImage: `url(${listData?.introduce?.image})` }}
        >
            <div className={pageStyle['content-footer']}>
                <div style={{ fontSize: '30px', fontWeight: 'bold', marginTop: '40px', textAlign: 'center' }}>
                    {listData?.introduce?.title}
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        width: '95%',
                    }}
                >
                    {listData?.introduce?.description}
                </div>

                <Button variant="outlined"
                    sx={{
                        color: '#fff', border: '4px solid #fff', borderRadius: '10px', padding: '10px 30px',
                        ':hover': {
                            border: '4.5px solid #f0f0f0'
                        }
                    }}
                >Learn More</Button>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" width="560" height="432" viewBox="0 0 560 432"
                className={pageStyle['banner-footer-desktop']}

            >
                <path id="oem-txt" d="M216,432C96.707,432,0,335.293,0,216S96.707,0,216,0H720V432Z" fill="#1890ff" opacity="0.75" />
            </svg>

            <svg width="100%" height="432px" xmlns="http://www.w3.org/2000/svg"
                className={pageStyle['banner-footer-mobile']}
            >
                <rect width="100%" height="100%" fill="#1890ff" opacity="0.75" />
            </svg>



        </div>
    </div>
}

import { Box, Button } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Header from '../../components/Header';
import React from 'react';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import pageStyle from "./index.module.scss";
import MenuItem from '../../utils/menu-items';
import Collapse from '@mui/material/Collapse';



export default function MainLayout() {

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [state, setState] = React.useState({
        left: false,
    });

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };


    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 200 }}
            role="presentation"
        >
            <List>
                {MenuItem?.filter(elment => elment.url !== 'contact')?.map((item, index) => {
                    return <ListItem key={index} disablePadding >
                        <ListItemButton
                            onClick={() => handleNavigate(item?.url)}
                        >
                            <ListItemText primary={item?.title} />
                        </ListItemButton>
                    </ListItem>
                })}
                <ListItem disablePadding >
                    <ListItemButton onClick={handleClick}>
                        <ListItemText primary='Our' />
                        {open ? <ExpandLess /> : <ExpandMore />}

                    </ListItemButton>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {[
                            { title: 'Our Expertise', url: 'our-expertise' },
                            { title: 'Our Partner', url: 'ourPartner' },
                            { title: 'Our Customer', url: 'ourCustomer' },
                            // { title: 'Our Awesome Team', url: 'our-awesome-team' },
                        ].map((elemnt, jk) => {
                            return <ListItemButton key={jk} sx={{ pl: 4 }}
                                onClick={() => handleNavigate(elemnt?.url)}
                            >
                                <ListItemText primary={elemnt?.title} />
                            </ListItemButton>
                        })
                        }

                    </List>
                </Collapse>

                {MenuItem?.filter(elment => elment.url === 'contact')?.map((item, index) => {
                    return <ListItem key={index} disablePadding >
                        <ListItemButton
                            onClick={() => handleNavigate(item?.url)}
                        >
                            <ListItemText primary={item?.title} />
                        </ListItemButton>
                    </ListItem>
                })}
            </List>
        </Box>
    );

    const handleNavigate = divId => {
        setState({ ...state, 'left': false });

        if(divId==='products'){
            return navigate('/products')
        }

        if (pathname.includes('/post') || pathname.includes('/products')) {
            navigate('/');
            setTimeout(() => {
                const divElement = document.getElementById(divId);
                if (divElement) {
                    divElement.scrollIntoView({ behavior: "smooth" });
                }
            }, 10)
        }
        const divElement = document.getElementById(divId);
        if (divElement) {
            divElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className={pageStyle['header']}>
                <div style={{ width: '850px' }}>
                    <Header handleNavigate={handleNavigate} />
                </div>
            </div>
            <div className={pageStyle['header-mobile']}>
                <Button onClick={toggleDrawer('left', true)}
                    sx={{
                        fontWeight: 600,
                        padding: '5px 20px',
                        color: '#fff',
                        backgroundColor: '#1890ff',
                        ":hover": {
                            backgroundColor: '#40a9ff'
                        },
                        margin: 'auto'
                    }}
                ><MenuIcon /></Button>
                <Drawer
                    anchor={'left'}
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                >
                    {list('left')}
                </Drawer>
            </div>
            <div style={{ width: '100%' }}>
                <Outlet />
            </div>

        </Box>



    )
}

import { CssBaseline } from '@mui/material';
import Route from './routes';


function App() {
  return (
    <>
      <Route />

      <CssBaseline />
    </>)
}

export default App;

import { useEffect, useState } from "react";



import pageStyle from "./index.module.scss";




export default function About() {

    const [totalMobile, setTotalMobile] = useState(0);
    const [totalWeb, setTotalWeb] = useState(0);
    const [totalClient, setTotalClient] = useState(0);
    const [totalYear, setTotalYear] = useState(0);

    useEffect(() => {
        getTotal(121, setTotalMobile);
        getTotal(141, setTotalWeb);
        getTotal(79, setTotalClient);
        getTotal(getYearEstablish2021(new Date()) + 1, setTotalYear);

    }, []);

    const getYearEstablish2021 = (data) => {
        const currentDate = new Date(data);
        const totalYears = currentDate.getFullYear() - 2021;
        return totalYears;
    }


    const getTotal = (value, setData) => {
        let index = 0;
        let timeOut = setInterval(() => {
            setData(index);
            index++;
            if (index === value) {
                clearInterval(timeOut)
            }
        }, 2);
        return () => clearInterval(timeOut);
    }


    return <div className={pageStyle['container']}>
        <div className={pageStyle['left']}>
            <div
                style={{
                    width: '320px', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '220px',
                    position: 'relative',
                }}
            >
                <div style={{ position: 'absolute', }} className="container-diamond">
                    <div className={'diamond'} style={{ backgroundColor: '#2196f3', width: '180px', height: '180px' }} >
                        <div className={'diamond-title'}
                            style={{ color: '#fff' }}
                        >
                            About Us
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={pageStyle['right']}>
            <div style={{ fontSize: '20px', fontWeight: 'bold', }}>
                Through our large capacity we dare to say we are different.
            </div>
            <div className={pageStyle['container-establish']}>
                <CustomOverview data={{ value: totalYear, title: 'Years' }} />
                <CustomOverview data={{ value: totalMobile, title: 'Mobile Apps' }} />
                <CustomOverview data={{ value: totalWeb, title: 'Web Apps' }} />
                <CustomOverview data={{ value: totalClient, title: 'Clients' }} />
            </div>
            <div className={pageStyle['description']}>
                With a team of professional, well-trained and experienced staff in the
                <span style={{ fontWeight: 'bold' }}> OT </span>
                and
                <span style={{ fontWeight: 'bold' }}> IT </span>
                field,
                we are always confident that we can bring customers the most satisfaction through solutions, products and services
            </div>
        </div>
    </div>
}

function CustomOverview({ data }) {
    return <div>
        <div style={{ width: '100%', color: '#1890ff', height: '40px', fontSize: '40px', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            +{data?.value}
        </div>
        <div style={{ width: '100%', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
            <div style={{ background: '#bfbfbf', height: '3px', width: '30px' }}></div>
        </div>
        <div style={{ width: '100%', fontWeight: 'bold', height: '20px', fontSize: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {data?.title}
        </div>
    </div>
}
import { Box } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import pageStyle from "./index.module.scss";



const email = 'phuongdl@siginx.com';

export default function Contact() {

    const handleCallClick = () => {
        window.location.href = `tel:0986882423`;
    };

    const handleEmailClick = () => {
        window.location.href = `mailto:${email}`;
    };


    return (
        <div className={pageStyle["container"]}>
            <div style={{
                display: "flex", justifyContent: "center", alignItems: 'center', height: '380px',

            }}>
                <div
                    className={"diamond"}
                    style={{
                        backgroundColor: "#52c41a",
                        width: "190px",
                        height: "190px"
                    }}
                >
                    <div className={"diamond-title"} style={{ color: "#fff" }}>
                        Our Office
                    </div>
                </div>
            </div>
            <div style={{ position: 'absolute', left: '-2%', top: '300px', zIndex: -1 }} className="container-diamond">
                <div className={'diamond'} style={{ backgroundColor: '#f9f0ff', width: '250px', height: '150px' }} >
                </div>
            </div>

            <div style={{ position: 'absolute', right: '2%', top: '100px', zIndex: -1 }} className="container-diamond">
                <div className={'diamond'} style={{ backgroundColor: '#fff0f6', width: '250px', height: '150px', transform: 'rotate(145deg)' }} >
                </div>
            </div>



            <div className={pageStyle["content"]}>
                <div className={pageStyle["left"]}>
                    <Box
                        sx={{
                            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                            maxWidth: "550px",
                            borderRadius: 2,
                            padding: 2,
                            backgroundColor: '#fff'
                        }}
                    >
                        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                            Head Quarter
                        </div>
                        <div style={{ display: 'flex', cursor: 'pointer' }} onClick={handleCallClick}>
                            <PhoneIcon style={{ color: "#1890ff", marginRight: '8px' }} />
                            (+84) 98 688 2423
                        </div>
                        <div style={{ display: 'flex', height: '30px', alignItems: 'center', cursor: 'pointer' }} onClick={handleEmailClick}>
                            <ForwardToInboxIcon style={{ color: "#1890ff", marginRight: '8px' }} />
                            {email}
                        </div>
                        <div>
                            <a href="https://www.google.com/maps?ll=10.849706,106.624358&z=7&t=m&hl=vi&gl=US&mapclient=embed&cid=4451213239351217505"
                                target="blank"
                                style={{ textDecoration: 'none', color: '#333', }}
                            >
                                <LocationOnIcon style={{ color: "#1890ff", }} /> 1501 Thai An
                                Building, 1A National Road, Trung My Tay Ward, District 12, HCM
                                city
                            </a>
                        </div>
                    </Box>
                </div>
                <div className={pageStyle["right"]}>
                    <div
                        style={{
                            maxWidth: "750px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: '100%',
                            height: '250px',
                        }}
                    >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1705.628548029631!2d106.62215884874874!3d10.849094577325092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752b46fa30e163%3A0x32266f9750ccb0ab!2sSiginx!5e0!3m2!1sen!2s!4v1705563536382!5m2!1sen!2s"
                            title="This is a unique title"
                            width="95%"
                            height="95%"
                            style={{ borderRadius: '10px', border: '3px solid #f0f0f0' }}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>

            <div className={pageStyle["footer"]}>
                <div style={{
                    backgroundColor: '#1a1825', width: '100%', height: '100px', position: 'absolute',
                    bottom: 0,
                    zIndex: -1
                }}>
                </div>
                <div style={{
                    width: '95%', maxWidth: '1200px', display: 'flex', justifyContent: 'space-around',
                    flexWrap: 'wrap'
                }}>
                    <div className={pageStyle["item-title"]}>
                        <div style={{ fontSize: '20px' }}>
                            SIGINX
                        </div>
                        <div style={{ padding: '4px 0' }}>
                            We have been in business for almost a decade. Helping you is our top priority.
                        </div>
                        <div>
                            © Copyright SIGINX
                        </div>
                    </div>
                    <div className={pageStyle["item-footer"]}>
                        <div style={{ fontSize: '20px' }}>
                            COMPANY
                        </div>
                        {['Team', 'History', 'Contact us', 'Locations'].map((item, index) => {
                            return <div key={index} style={{ padding: '4px 0' }}>
                                {item}
                            </div>
                        })}
                    </div>
                    <div className={pageStyle["item-footer"]}>
                        <div style={{ fontSize: '20px' }}>
                            RESOURCES
                        </div>
                        {['Resource', 'Resource name', 'Another resource', 'Final resource'].map((item, index) => {
                            return <div key={index} style={{ padding: '4px 0' }}>
                                {item}
                            </div>
                        })}
                    </div>
                    <div className={pageStyle["item-footer"]}>
                        <div style={{ fontSize: '20px' }}>
                            LEGAL
                        </div>
                        {['Privacy policy', 'Terms of use'].map((item, index) => {
                            return <div key={index} style={{ padding: '4px 0' }}>
                                {item}
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}


import { Box, Card, CardActionArea } from "@mui/material"

import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

import Adlinkd from "../../assets/images/OurPartner/Adlink.png";
import Advantech from "../../assets/images/OurPartner/Advantech.jpeg";
import Yokogawa from "../../assets/images/OurPartner/Yokogawa.png";
import Megahost from "../../assets/images/OurPartner/Megahost.png";
import DFT from "../../assets/images/OurPartner/dft.webp";



const listOurPartner = [
    { alt: 'Adlinkd', url: Adlinkd },
    { alt: 'Advantech', url: Advantech },
    { alt: 'Yokogawa', url: Yokogawa },
    { alt: 'Megahost', url: Megahost },
    { alt: 'DFT', url: DFT },
];


export default function OurPartner() {



    return <div style={{ marginBottom: '150px' }}>
        <div style={{
            display: "flex", justifyContent: "center", alignItems: 'center', height: '250px',
            marginBottom: '20px'
            , position: 'relative',
        }}>
            <div
                className={"diamond"}
                style={{
                    backgroundColor: "#1890ff",
                    width: "220px",
                    height: "160px"
                }}
            >
                <div className={"diamond-title"} style={{ color: "#fff" }}>
                    Our Partner
                </div>
            </div>

            <div style={{ position: 'absolute', left: 0, right: 0, top: '150px', zIndex: -1 }} className="container-diamond">
                <div className={'diamond'} style={{ backgroundColor: '#d9f7be', width: '300px', height: '180px' }} >
                </div>
            </div>
            <div style={{ position: 'absolute', right: 0, top: '-80px', zIndex: -1 }} className="container-diamond">
                <div className={'diamond'} style={{ backgroundColor: '#fff0f6', width: '250px', height: '150px', transform: 'rotate(145deg)' }} >
                </div>
            </div>
        </div>

        <div style={{ border: '3px solid #f7fafd', backgroundColor: '#ffff', width: '96%', margin: 'auto', borderRadius: '10px', }}>
            <Carousel
                additionalTransfrom={0}
                arrows={false}
                showDots={false}
                autoPlay
                autoPlaySpeed={1}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                beforeChange={false}
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay={false}

                sliderClass=""
                slidesToSlide={1}
                swipeable
                transitionDuration={1000}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 5,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 3,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 4,
                        partialVisibilityGutter: 30
                    }
                }}

            >
                {listOurPartner.map((item, index) => {
                    return <Box
                        key={index}
                        sx={{
                            minWidth: '120px',
                            maxWidth: '300px',
                            margin: "auto",
                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                            height: '170px',
                        }}
                    >
                        <Card sx={{ width: "95%", height: "65%", borderRadius: '0px', boxShadow: 0 }}>
                            <CardActionArea style={{ height: '100%' }}>
                                <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={item?.url} alt={item.alt}
                                        style={{ maxWidth: '98%', maxHeight: '98%' }}
                                    />
                                </div>
                            </CardActionArea>
                        </Card>
                    </Box>
                })}
            </Carousel>
        </div>
        {/* <div style={{
            border: '3px solid #f7fafd', backgroundColor: '#ffff', width: '96%', margin: 'auto', borderRadius: '10px', display: 'flex', flexWrap: 'wrap',
            height: '100px'
        }}>
            <h1> Our Customer</h1>
        </div> */}
    </div>
}


import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



import logo from "../../assets/images/logo/logo.png"
import ListMenuItem from '../../utils/menu-items';
import { useState } from 'react';


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));



export default function Header({ handleNavigate }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const openEl = Boolean(anchorEl);



    const handleClickEl = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleUrlTypeGroup = (data) => {
        handleNavigate(data);
        setTimeout(() => {
            handleClose();
        }, 5)
    }

    return <div style={{
        width: '100%', height: '80%', display: 'flex', alignItems: 'center',
        borderRadius: '5px',
        padding: '0 10px',
        backgroundColor: '#fff',
    }}
    >
        <div style={{ width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={logo} alt="logo-siginx"
                width={'100%'}
            />
        </div>
        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {ListMenuItem?.filter(elment => elment.url !== 'contact')?.map((item, index) => {
                return <Button
                    key={index}
                    sx={{
                        fontWeight: 600,
                        padding: '5px 20px',
                        color: '#333'
                    }}
                    onClick={() => handleNavigate(item?.url)}
                >
                    {item?.title}
                </Button>
            })}

            <Button
                id="demo-customized-button"
                aria-controls={openEl ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openEl ? 'true' : undefined}
                disableElevation
                onClick={handleClickEl}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                    fontWeight: 600,
                    padding: '5px 20px',
                    color: '#333'
                }}
                // onMouseEnter={handleClickEl}
            >
                Our
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={openEl}
                onClose={handleClose}
            >
                {[
                    { title: 'Our Expertise', url: 'our-expertise' },
                    { title: 'Our Partner', url: 'ourPartner' },
                    { title: 'Our Customer', url: 'ourCustomer' },
                    // { title: 'Our Awesome Team', url: 'our-awesome-team' },
                ].map((element, jk) => {
                    return <MenuItem key={jk}
                        onClick={() => handleUrlTypeGroup(element?.url)}
                    >
                        {element?.title}
                    </MenuItem>
                })}
            </StyledMenu>

            {ListMenuItem?.filter(elment => elment.url === 'contact')?.map((item, index) => {
                return <Button
                    key={index}
                    sx={{
                        fontWeight: 600,
                        padding: '5px 20px',
                        color: '#333'
                    }}
                    onClick={() => handleNavigate(item?.url)}
                >
                    {item?.title}
                </Button>
            })}
        </div>
    </div>
}


import Box from "@mui/material/Box";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from "react-router-dom";



import pageStyle from "./index.module.scss";
import SIGINXxVNBxVTI1 from "../../assets/images/ExploreNews/SiginX x VNB x VTI 1.png";
import SIGINXxVNBxVTI2 from "../../assets/images/ExploreNews/SiginX x VNB x VTI 2.png";
import SIGINXxVNBxVTI3 from "../../assets/images/ExploreNews/SiginX x VNB x VTI 3.png";
import SIGINXxVNBxVTI4 from "../../assets/images/ExploreNews/SiginX x VNB x VTI 4.png";


const listRow = [
    {
    _id: "4",
        title: `SIGINX VÀ VTI SOLUTIONS KÝ KẾT MOU - MỞ RA CƠ HỘI PHÁT TRIỂN, HỢP TÁC TRONG NHIỀU HẠNG MỤC`,
        date: "11/07/2024",
        image: SIGINXxVNBxVTI1,
        mainContent: [
            {
                content: `Vào ngày 11/07/2024, lễ ký kết Biên bản ghi nhớ (MoU) giữa công ty TNHH Công Nghệ SIGINX (SIGINX) (Thành viên VNB Group) và công ty TNHH VTI Solutions (VTI Solutions (VTI Solutions) đã được diễn ra với tinh thần hợp tác song phương, toàn diện và hỗ trợ lẫn nhau trong nhiều hạng mục giữa SIGINX và VTI Solutions.`,
            },
            {
                content: `Sự kiện được tổ chức với sự tham gia của ông Phạm Thanh Hà, Giám đốc VTI Solutions, và ông Dương Lâm Phương, CEO của Siginx, cùng với các quản lý cao cấp từ hai phía.`,
            },
            {
                content: `Đại diện của cả hai công ty đã cùng nhau thể hiện sự vui mừng khi hai công ty trở thành đối tác, cam kết mang lại những giải pháp hiệu quả và đột phá cho thị trường, đặc biệt là trong lĩnh vực chuyển đổi số toàn diện.`,
                image: [SIGINXxVNBxVTI2],
            },
            {
                content: `SIGINX phát triển với mục tiêu cung cấp cho khách hàng những sản phẩm, dịch vụ CNTT và giải pháp chuyển đổi số đáng tin cậy và hiệu quả nhất. Với đội ngũ nhân viên chuyên nghiệp, được đào tạo bài bản và giàu kinh nghiệm trong lĩnh vực OT và IT, chúng tôi luôn tự tin có thể mang đến cho khách hàng sự hài lòng nhất thông qua các giải pháp, sản phẩm và dịch vụ về số hóa.`,
                image: [SIGINXxVNBxVTI3],
            },
            {
                content: `VTI Solutions là một công ty hàng đầu về Công nghệ thông tin có trụ sở tại Hàn Quốc, Nhật Bản, Singapore và Việt Nam. VTI Solutions cung cấp các phần mềm & giải pháp toàn diện cho khách hàng có mong muốn tùy chỉnh sản phẩm theo yêu cầu đặc thù và mở rộng quy mô trong mọi ngành sản xuất.`,
                image: [SIGINXxVNBxVTI4],
            },
            {
                content: `VNB GROUP là một công ty hàng đầu hoạt động trong nhiều lĩnh vực công nghiệp và công nghệ. Các lĩnh vực hoạt động bao gồm: Cung cấp giải pháp công nghiệp 4.0, với các sản phẩm và dịch vụ như Robot, IoT, Vision, nhà kho thông minh... Đơn vị thiết kế giải pháp và cung cấp thiết bị, lắp đặt và chuyển giao công nghệ trong lĩnh vực ứng dụng Robot trong các dây chuyền sản xuất công nghiệp. Thiết kế cung cấp và lắp đặt, Cải tạo và Tích hợp các dây chuyền tự động hóa sản xuất.`,
            },
            {
                content: `Sự kết hợp này không chỉ mở ra nhiều cơ hội kinh doanh mà còn thể hiện sự hợp tác bền vững, góp phần thúc đẩy sự phát triển trong cả hai công ty và ngành công nghệ thông tin tại Việt Nam.`,
            },
        ],
    },
    {
        _id: '3',
        title: `MỜI TRẢI NGHIỆM IOT PLATFORM TRÊN ĐIỆN THOẠI`,
        date: '11/11/2023',
        image: 'https://bizweb.dktcdn.net/100/455/629/articles/qr-png.png?v=1676946185157',
        mainContent: [
            {
                content: `AP Cloud là giải pháp giám sát, điều khiển từ xa và lưu trữ dữ liệu sản xuất trực tuyến trên đa nền tảng công nghệ điện toán đám mây.

                Ưu điểm của giải pháp: Nền tảng dữ liệu được lưu trên hệ thống đám mây tiêu chuẩn quốc tế, đảm bảo an toàn và bảo mật; Kết nối quản lý tập trung nhiều thiết bị; Tiết kiệm chi phí thiết lập, lưu trữ và bảo trì…

                Quý khách hàng có thể tải ứng dụng AP Platform trên CH Play hoặc App Store để trải nghiệm ứng dụng, ngoài ra Quý khách hàng có thể trải nghiệm web server bằng cách nhấp vào đây.

                Tên đăng nhập: Demo
                Mật khẩu: demo123`,
                image: ['https://bizweb.dktcdn.net/100/455/629/files/bia-qrcode.png?v=1679543050344'],
            },
        ]
    },
    {
        _id: '2',
        title: 'SIGINX VÀ HOTEC THAM DỰ NGÀY HỘI ĐỔI MỚI SÁNG TẠO TỈNH BẾN TRE 2022',
        date: '16/12/2023',
        image: 'https://bizweb.dktcdn.net/100/455/629/articles/z3964359397237-db998d62999cb744dff719b99750f2c7.jpg?v=1671520206447',
        mainContent: [
            {
                content: 'Ngày 16/12, UBND tỉnh Bến Tre tổ chức Ngày hội Đổi mới sáng tạo tỉnh Bến Tre năm 2022 “Ben Tre Innovation Day-2022”.',
                image: ['https://bizweb.dktcdn.net/100/455/629/files/z3964358263859-cc7f634670bcc4096fbb1332a9ae0881.jpg?v=1671518774214'],
            },
            {
                content: `Ngày hội Đổi mới sáng tạo tỉnh Bến Tre năm 2022 diễn ra với chuỗi sự kiện như: Cuộc thi Hackathon giải pháp,
                ý tưởng sáng tạo giải quyết vấn đề của địa phương hoặc xã hội; cuộc thi đổi mới sáng tạo trong doanh nghiệp “Innovation in yourself”; diễn đàn CEO Ben Tre Forum “Giải pháp kiến tạo Bến Tre thông minh”; Pitching doanh nghiệp và nhà đầu tư; cùng các hoạt động trải nghiệm sáng tạo STEM; trưng bày, giới thiệu mô hình đổi mới sáng tạo và chuyển đổi số; hành trình đổi mới sáng tạo “Factory Innovation Tour”.`,
                image: ['https://bizweb.dktcdn.net/100/455/629/files/z3964358264823-68ac74d56e5c4e621a3d84ba7d767af3.jpg?v=1671518903542', 'https://bizweb.dktcdn.net/100/455/629/files/z3964357408972-a890dd2bf861a5036d83799183938381.jpg?v=1671518952514'],
            },
            {
                content: `Tại đây, Công ty TNHH Công nghệ Siginx và Trường Cao đẳng Kinh tế - Kỹ thuật Thành phố Hồ Chí Minh (HOTEC) tham gia trưng bày mô hình ứng dụng IoT trong nông nghiệp, mô hình này đang được giảng dạy ở trường HOTEC là kết quả của sự hợp tác giữa SIGINX và HOTEC.`,
                image: ['https://bizweb.dktcdn.net/100/455/629/files/z3964361469399-d477fad6d7b23a026ceaa74681975b5d.jpg?v=1671519840173'],
            }
        ]
    },
    {
        _id: '1',
        title: `TRIỂN LÃM HỘI NGHỊ TỔNG KẾT CHƯƠNG TRÌNH HỢP TÁC PHÁT TRIỂN KINH TẾ - XÃ HỘI GIỮA THÀNH PHỐ HỒ CHÍ MINH VỚI CÁC TỈNH, THÀNH PHỐ THUỘC VÙNG ĐỒNG BẰNG SÔNG CỬU LONG        `,
        date: '11/03/2023',
        image: 'https://bizweb.dktcdn.net/100/455/629/articles/z4178550230947-7281923c532c443e9fb4d96af57dd884.jpg?v=1678696321797',
        mainContent: [
            {
                content: `Sáng 11/3, tại tỉnh Bến Tre, UBND TPHCM phối hợp với UBND tỉnh Bến Tre và UBND các tỉnh, thành vùng Đồng bằng sông Cửu Long (ĐBSCL) tổ chức Hội nghị tổng
                 kết Chương trình hợp tác phát triển kinh tế-xã hội giữa TPHCM với các tỉnh, thành vùng ĐBSCL giai đoạn 2016 – 2022 và triển khai phương hướng đến năm 2025`,
                image: ['https://bizweb.dktcdn.net/100/455/629/files/z4178550167212-03c29da5105c31a8d411566679490622.jpg?v=1678696037482'],
            },
            {
                content: `Tại hội nghị, Siginx tham gia triển lãm các giải pháp IoT trong nông nghiệp, nuôi trồng thủy hải sản...`,
                image: ['https://bizweb.dktcdn.net/100/455/629/files/z4178549876728-9b143f5e662bcefb0b7b2f64e4d30809.jpg?v=1678696309731'],
            },
        ]
    }
];

export default function ExploreNews() {

    const navigate = useNavigate();

    const handleHref = (data) => {
        navigate('/post/' + data);
    }

    return <div className={pageStyle['container']}>
        <div style={{ position: 'absolute', left: '-260px', top: '10px', zIndex: -1 }} className="container-diamond">
            <div className={'diamond'} style={{ backgroundColor: '#f9f0ff', width: '200px', height: '100px' }} >
            </div>
        </div>

        <div style={{
            width: '100%', display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <div style={{
                backgroundColor: '#52c41a', width: '250px', height: '70px',
                padding: '0 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px'
            }} 
            className="container-diamond"
            >
                <div
                    style={{
                        color: '#fff',
                        fontSize: '30px', fontWeight: 'bold'
                    }}
                >
                    Explore News
                </div>
            </div>

        </div>
        <div className={pageStyle['left']}>
            <div style={{ width: '90%', maxWidth: '550px', padding: '10px 0' }}>
                <Box
                    sx={{
                        cursor: 'pointer', padding: '10px',
                        ':hover': {
                            boxShadow: '0px 0 30px rgba(1, 41, 112, 0.08)',
                        },
                        backgroundColor: '#fff'
                    }}
                    onClick={() => { handleHref(listRow[0]?._id) }}
                >
                    <div style={{ width: '100%', height: '300px', alignItems: 'center', justifyContent: 'center', display: 'flex', }}>
                        {listRow?.length !== 0 &&
                            <img src={listRow[0]?.image} alt="siginx"
                                style={{ borderRadius: '15px', maxWidth: '98%', maxHeight: '98%' }}
                            />
                        }
                    </div>
                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}
                        className={pageStyle['title-hidden']}
                    >
                        {listRow[0]?.title}
                    </div>
                    <div style={{ fontSize: '14px', height: '30px', display: 'flex', alignItems: 'center' }}>
                        {listRow[0]?.date}
                    </div>
                    <div style={{ color: '#5e657a' }} className={pageStyle['title-overview']}>
                        {listRow[0]?.mainContent[0].content}
                    </div>
                </Box>
            </div>
            <div style={{ position: 'absolute', left: '-80px', bottom: '-20px', zIndex: -1 }} className="container-diamond">
                <div className={'diamond'} style={{ backgroundColor: 'rgb(239 247 255)', width: '200px', height: '200px' }} >
                </div>
            </div>
        </div>
        <div className={pageStyle['right']}>
            <div style={{
                position: 'absolute',
                right: '50px',
                fontSize: '18px',
                top: '-10px',
                color: '#1890ff',
                cursor: 'pointer'
            }}>View more
                <ArrowRightAltIcon
                    style={{ position: 'absolute' }}
                />
            </div>
            <div style={{ width: '90%', margin: 'auto', padding: '10px 0', position: 'relative' }}>
                {listRow.filter((element => element?._id !== listRow[0]?._id)).map((item, index) => {
                    return <CustomPostRow key={index} data={item} func={() => { handleHref(item?._id) }} />
                })}

            </div>
            <div className={pageStyle['custom-diamond'] + " container-diamond"}>
                <div className={'diamond'} style={{
                    backgroundColor: '#fff', width: '100%', height: '100%',
                    border: '10px solid #b2ebf2',
                }} >
                </div>
            </div>

        </div>
    </div>
}



function CustomPostRow({ data, func }) {
    return <Box sx={{
        cursor: 'pointer', display: 'flex', height: '145px', alignItems: 'center',
        marginTop: '10px',
        padding: '10px',
        ':hover': {
            boxShadow: '0px 0 30px rgba(1, 41, 112, 0.08)',
        },
        backgroundColor: '#fff'
    }}
        onClick={func}
    >
        <div style={{
            width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90%',
        }}>
            <img src={data?.image} alt="siginx"
                style={{ borderRadius: '15px' }}
                width={'100%'}
            />
        </div>

        <div style={{ width: '70%', maxHeight: '90%', paddingLeft: '10px', }}>
            <div style={{
                fontSize: '18px', fontWeight: 'bold',
                maxHeight: '63px',
                width: '100%'
            }}
                className={pageStyle['title-hidden']}
            >
                {data?.title}
            </div>
            <div style={{ fontSize: '14px', height: '25px', display: 'flex', alignItems: 'center', width: '100%' }}>
                {data?.date}
            </div>
            <div style={{ color: '#5e657a', width: '100%' }}
                className={pageStyle['title-hidden']}
            >
                {data?.mainContent[0].content}
            </div>
        </div>
    </Box>
}
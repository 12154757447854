

import { Button } from "@mui/material";



import pageStyle from "./index.module.scss";



export default function OurExpertise() {




    return <div className={pageStyle['container']}>
        <div className={pageStyle['left']}>
            <div className={'diamond'} style={{
                backgroundColor: '#2196f3', width: '200px', height: '200px',
                zIndex: 1
            }} >
                <div className={'diamond-title'}
                    style={{ color: '#fff' }}
                >
                    Our Expertise
                </div>

            </div>

            <div className="container-diamond"
                style={{
                    position: 'absolute',
                    top: '-25px',
                    right: '30%',

                }}
            >
                <div style={{
                    transform: 'rotate(45deg)',
                    border: '2px solid #bbdefb',
                    width: '350px',
                    height: '350px',
                    borderRadius: '20px'
                }}>
                </div>
            </div>
            <div className="container-diamond"
                style={{
                    position: 'absolute',
                    top: '3px',
                    right: '33.5%',

                }}
            >
                <div style={{
                    transform: 'rotate(45deg)',
                    border: '2px solid #bbdefb',
                    width: '300px',
                    height: '300px',
                    borderRadius: '20px'
                }}>
                </div>
            </div>
        </div>
        <div className={pageStyle['right']}>
            <div style={{ fontSize: '20px', fontWeight: 'bold', padding: '10px 0' }}>
                A successful project is built with a successful team.
            </div>
            <div>
                Which is why we provide you with the human resources you need to build whenever you need them. Our on_shoring & near
                offshoring teams are trained and supervised as part of Us. Your team is dedicated to your projects. And the team is flexible!
            </div>
            <div style={{
                padding: '20px 0', display: 'flex', flexWrap: 'wrap',
                alignItems: 'center',
                maxWidth: '490px'
            }}>
                {['Website development service', 'UX/UI design', 'Mobile application service',
                    'Computer vision', 'AI conversational',
                    'Cloud-based IoT applications', 'M2M network development', 'Device development',
                    'Nature language procesing (NLP)', 'Data analytics'
                ].map((item, index) => {
                    return <Button key={index} variant="outlined" sx={{ padding: '3px 12px', ml: 1, mr: 1, mt: 1 }}>{item}</Button>
                })
                }

            </div>
        </div>
    </div>
}

import About from "../About";
import Contact from "../Contact";
import ExploreNews from "../ExploreNews";
// import OurAwesomeTeam from "../OurAwesomeTeam";
import OurPartner from "../OurPartner";
import Service from "../Service";

import banner from "../../assets/images/banner/banner.png";

import "./index.scss";
import OurExpertise from "../OurExpertise";
import OurCustomer from "../OurCustomer";





export default function Home() {



    return (
        <div className="container">
            <div>
                <div id="home">
                    <div className="home-left">
                        <div style={{ fontWeight: "bold" }}>
                            <h1 style={{ fontSize: '35px' }}>{'LET’S CREATE BETTER RESULTS'}</h1>
                            <div style={{
                                fontSize: '22px', maxWidth: '650px', fontWeight: 'normal',
                                fontStyle: 'italic', color: '#7f7f7f'
                            }}>
                                SIGINX was established with the goal of providing customers
                                with the most reliable and effective IT products, services, and
                                digital transformation solutions.
                            </div>
                        </div>
                    </div>
                    <div className="home-right">
                        <div style={{ maxWidth: "670px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                                src={banner}
                                alt="logo"
                                width={"100%"}
                            />
                            <div className="animate-border">
                                <span></span><span></span><span></span><span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ position: 'absolute', left: '-2%', top: '300px', zIndex: -1 }} className="container-diamond">
                    <div className={'diamond'} style={{ backgroundColor: '#e9f9fb', width: '200px', height: '200px' }} >
                    </div>
                </div>
                <div
                    className="container-diamond custom-diamond">
                    <div className={'diamond'} style={{ backgroundColor: '#eff7ff', width: '100%', height: '100%' }} >
                    </div>
                </div>
                <div id="about">
                    <About />
                </div>
                <div id="service">
                    <Service />
                </div>
                <div id="exploreNews">
                    <ExploreNews />
                </div>
                <div id="our-expertise">
                    <OurExpertise />
                </div>
                <div id="ourPartner">
                    <OurPartner />
                </div>
                <div id="ourCustomer">
                    <OurCustomer />
                </div>
                {/* <div id="our-awesome-team">
        <OurAwesomeTeam />
    </div> */}
                <div id="contact">
                    <Contact />
                </div>
            </div>

        </div>
    );
}







import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { CardActionArea } from '@mui/material';
import WebsiteDevelopment from "../../assets/images/service/Website-development-service.jpeg"
import MobileApplication from "../../assets/images/service/Mobile-application-service.jpeg"
import UIUX from "../../assets/images/service/UXUI.webp"
import CloudBased from "../../assets/images/service/ Cloud-based IoT applications.png"
import M2MNetwork from "../../assets/images/service/M2M network development.jpeg"
import DeviceDevelopment from "../../assets/images/service/Device development.jpeg"
import ComputerVision from "../../assets/images/service/Computer vision.png"
import AIConversational from "../../assets/images/service/AI conversational & Nature language procesing (NLP).png"
import DataAnalytics from "../../assets/images/service/Data analytics.png"


import pageStyle from "./index.module.scss";

const listDataOne = [
    {
        title: 'Website development service',
        content: 'Create stunning websites to run your business effectively and increase your brand awareness',
        url: WebsiteDevelopment
    },
    {
        title: 'Mobile application service',
        content: `Develop high performing, digitally transformative and user-friendly mobile applications for Android, 
        iOS & cross-platform devices with an aim to develop “Smartphone applications that you can use every day”`,
        url: MobileApplication
    },
    {
        title: 'UX/UI design',
        content: 'Create an engaging website design that matches your brand image & drives conversion',
        url: UIUX
    },
    {
        title: 'Cloud-based IoT applications',
        content: `Setting & management application, 
        Real-time monitoring application, 
        Mobility application, 
        Data analytics`,
        url: CloudBased
    },
    {
        title: 'M2M network development',
        content: `TCP/IP based M2M network, 
        Gateway application…, 
        Wireless connectivity`,
        url: M2MNetwork
    },
    {
        title: 'Device development',
        content: `Firmware development, 
        Device driver`,
        url: DeviceDevelopment
    },
    {
        title: 'Computer vision',
        content: `OCR (Optical character recognition), 
        eKYC (Electronic know your customer), 
        Vision inspection, 
        Smart insight`,
        url: ComputerVision
    },
    {
        title: 'AI conversational & Nature language procesing (NLP)',
        content: `Text to speech, 
        Chatbots, 
        Voice processing, 
        Speech to text`,
        url: AIConversational
    },
    {
        title: 'Data analytics',
        content: `Anomaly dectection & Analysis, 
        Predictive maintenance, 
        Demand forecasting, 
        Recommendation`,
        url: DataAnalytics
    },
]

export default function Service() {


    return <div className={pageStyle['container']}>
        <div className={pageStyle['left']}>
            <div style={{
                width: '95%', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}>
                <div className={pageStyle['container-diamond']}>
                    <div className={pageStyle['diamond']} >
                        <div className={'diamond-title'}
                        >
                            Service
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className={pageStyle['right']}>
            {listDataOne?.map((item, index) => {
                return <div key={index} style={{
                    width: '48%', maxWidth: 300, position: 'relative',
                    marginTop: '25px',
                }}>
                    <div style={{
                        width: '84%',
                        display: 'flex', alignItems: 'center',
                        justifyContent: 'right',
                        height: '100px',
                        position: 'absolute',
                        // backgroundColor: '#fff',
                        // borderRadius: '10px',
                        // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                        left: '6%',
                        top: '-14px',
                        zIndex: 1,
                    }}>
                        <img src={item?.url} alt='service'
                            style={{
                                width: '220px',
                                maxHeight: '98%',
                                borderRadius: '10px',
                                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',

                            }}
                        />
                    </div>

                    <Card sx={{
                        borderRadius: '10px',
                        ":hover": {
                            borderRadius: 0,
                            transition: '.5s',
                        },
                    }}>
                        <CardActionArea sx={{
                            backgroundColor: '#fff', height: '270px',
                        }}>
                            <div
                                className={pageStyle['title-hidden-card']}

                            >
                                {item?.title}
                            </div>
                            <div
                                className={pageStyle['title-hidden-content']}
                            >
                                {item?.content}
                            </div>
                            <div style={{
                                position: 'absolute',
                                bottom: 0,
                                height: '60px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Button variant="outlined"
                                    component="div"
                                    sx={{ width: '90%' }}
                                >See detail</Button>
                            </div>
                        </CardActionArea>
                    </Card>
                </div>
            })}

        </div>
    </div>
}
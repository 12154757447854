import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';



import imgNk03 from "../../assets/images/Product/nk03-s.png"
import imgN04 from "../../assets/images/Product/n04-s.png"
import imgN07 from "../../assets/images/Product/n07-s.png"
import imgP04 from "../../assets/images/Product/p04-s.png"
import imgP07 from "../../assets/images/Product/p07-s.png"
import imgP10 from "../../assets/images/Product/p10-s.png"
import imgP12 from "../../assets/images/Product/p12-s.png"
import imgP15 from "../../assets/images/Product/p15-2s.png"
import imgPanelPc from "../../assets/images/Product/panel-pc-banner.jpeg"
import imgHardware from "../../assets/images/Product/hardware-hmi.jpeg"
import imgNPp215P01 from "../../assets/images/Product/NPP-215P01-Front.png"
import imgOem from "../../assets/images/Product/oem-bg.jpeg"
import imgHMIOperatorPanel from "../../assets/images/Product/hmi-operator-panel-banner.jpeg"
import img156p01 from "../../assets/images/Product/156p01-Front.png"
import imgNBP01 from "../../assets/images/Product/NBP-01_01.gif"
import imgR12_02 from "../../assets/images/Product/R12_02.png"
import imgR15_s from "../../assets/images/Product/R15_s.png"
import imgR17_s from "../../assets/images/Product/R17_s.png"
import imgR19_s from "../../assets/images/Product/R19_s.png"
import imgR21_s from "../../assets/images/Product/R21_s.png"
import imgHardwarePanel from "../../assets/images/Product/hardware-panel.jpeg"
import imgIotGatewayBanner from "../../assets/images/Product/iiot-gateway-banner.jpeg"
import imgGw01Render from "../../assets/images/Product/GW-01-Render-240.png"

import { useEffect, useState } from 'react';
import HMIOperatorPanel from './components/HMIOperatorPanel';
import Contact from '../Contact';




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const listTab = [
    { value: 0, title: 'HMI Operator Panel' },
    { value: 1, title: 'Idustrial Panel PC' },
    { value: 2, title: 'Protocol Conversion Gateway' },
]

const listHmiOperatorPanel = {
    title: 'HMI OPERATOR PANEL',
    description: 'Novakon offers a full range of Human Machine Interface (HMI) Operator Panels catering across vertical markets including Power Metering, Smart Freezers, Torque Controllers, CNC machines, Indoor Environmental Monitoring, Production Equipment, Building Automation, Wastewater Treatment, IIoT Sewing Machines, etc..',
    label: 'HMI Operator Panel Products',
    banner: imgHMIOperatorPanel,
    listProduct: [
        { image: imgNk03, title: 'NK03 Keypad HMI', description: '3.5" TFT, 320x240, Keypad HMI, ARM9 400MHz' },
        { image: imgN04, title: 'N04 Touch Screen HMI', description: '4.3" TFT, 480x272, Resistive Touch, ARM9 400MHz' },
        { image: imgN07, title: 'N07 Touch Screen HMI', description: '7" TFT, 800x480, Resistive Touch, ARM9 400MHz' },
        { image: imgP04, title: 'P04 Touch Screen HMI', description: '4.3" TFT, 480x272, Resistive Touch, ARM Cortex A8 1GHz' },
        { image: imgP07, title: 'P07 Touch Screen HMI', description: '7" TFT, 800x480, Resistive Touch, ARM Cortex A8 1GHz' },
        { image: imgP10, title: 'P10 Touch Screen HMI', description: '10.2" TFT, 800x480, Resistive Touch, ARM Cortex A8 1GHz' },
        { image: imgP12, title: 'P12 Touch Screen HMI', description: '12.1" TFT, 1280x800, Resistive Touch, ARM Cortex A8 1GHz' },
        { image: imgP15, title: 'P15 Touch Screen HMI', description: '15.6" TFT, 1366x768, Resistive Touch, ARM Cortex A8 1GHz' },
    ],
    about: {
        title: 'HMI for Visualization & IIoT', description: `iFace Designer® is a cross-platform visualization software, from which the compiled program can be installed and run on both HMI and Industrial Panel PC. Tabs in ribbun UI make it simple and straightforward to develop projects. With a few clicks you can easily reach functions and properties.`,
        image: imgHardware,
    },
    introduce: {
        title: 'VISUALIZATION SOFTWARE',
        description: `Integrated with NOVAKON HMI Software, iFace Designer, NOVAKON HMI Operator Panels not only visualize the control and monitoring process with built-in graphical user interface (GUI) widgets, but also feature IIoT functions with advanced remote connectivity and communication protocols that easily push data and event messages to remote servers to avoid downtime and reduce operating costs.`,
        image: imgOem
    }
}
const listIndustrialPanelPC = {
    title: 'INDUSTRIAL PANEL PC',
    description: `Novakon Industrial panel PCs are reliable and multifunctional. We offer waterproof IP65 stainless steel panel PC, railway EN50155 certified panel PC, sunlight readable panel PC, and various all-in-one panel PCs designed for indoor and outdoor deployment.`,
    label: 'Industrial Panel PC Products',
    banner: imgPanelPc,
    listProduct: [
        { image: imgNPp215P01, title: 'NPP-215P01 Industrial Panel PC', description: '21.5" Intel Celeron Processor J6412 Fanless Modular Panel PC' },
        { image: img156p01, title: 'NPP-156P01 Industrial Panel PC', description: '15.6" Intel Celeron Processor J6412 Fanless Modular Panel PC' },
        { image: imgNBP01, title: 'NBP-01 Fanless Embedded Box PC', description: 'Intel Celeron Processor J6412 Embedded Box PC' },
        { image: imgR12_02, title: 'R12 Industrial Panel PC', description: '12.1" Intel Celeron N2930/Atom E3845 Fanless Industrial Panel PC' },
        { image: imgR15_s, title: 'R15 Industrial Panel PC', description: '15" Intel Celeron N2930/Atom E3845 Fanless Industrial Panel PC' },
        { image: imgR17_s, title: 'R17 Industrial Panel PC', description: '17" Intel Celeron N2930/Atom E3845 Fanless Industrial Panel PC' },
        { image: imgR19_s, title: 'R19 Industrial Panel PC', description: '19" Intel Celeron N2930/Atom E3845 Fanless Industrial Panel PC' },
        { image: imgR21_s, title: 'R21 Industrial Panel PC', description: '21.5" Intel Celeron N2930/Atom E3845 Fanless Industrial Panel PC' },
    ],
    about: {
        title: 'Industrial Panel PC for SCADA',
        description: `Integrated with Novakon HMI Software, iFace Designer, Novakon Industrial panel PC serves as a Supervisory Control and Data Acquisition (SCADA) system that provides a high-level process supervisory management.`,
        image: imgHardwarePanel,
    },
    introduce: {
        title: 'VISUALIZATION SOFTWARE',
        description: `iFace Designer® is a cross-platform visualization software, from which the compiled program can be installed and run on both HMI and Industrial Panel PC. Tabs in ribbun UI make it simple and straightforward to develop projects. With a few clicks you can easily reach functions and properties.`,
        image: imgOem
    }
}

const listProtocol = {
    title: 'PROTOCOL CONVERSION GATEWAY',
    description: `Novakon protocol conversion gateway is the perfect solution to help the challenging work for legacy equipment upgrade, data acquisition and protocol conversion across all industries whether it is for factory automation, agriculture technology, building automation, energy management, etc…`,
    label: 'Protocol Conversion Gateway Products',
    banner: imgIotGatewayBanner,
    listProduct: [
        { image: imgGw01Render, title: 'GW-01 Protocol Conversion Gateway', description: 'ARM Cortex A8 1GHz, 512M SDRAM, 4GB eMMC' },
    ],
    about: {
        title: 'Gateway for Data Acquisition and Protocol Conversion',
        description: `Having trouble with upgrading legacy equipment to be IIoT ready in the factory? Novakon gateway product series is powered by a web-based and simple-to-use programming software, iM-Connect®, that supports a variety of industrial communication protocols, PLC drivers, and IIoT Cloud connections as simple but reliable protocol conversion and data acquisition solution, enabling dataflow communication from legacy equipment to IT network effortlessly.`,
        image: imgHardwarePanel,
    },
    introduce: {
        title: 'WEB-BASED IIOT SOFTWARE',
        description: `iM-Connect® is a web-based and simple-to-use programming software that supports a variety of industrial communication protocols, PLC drivers, and IIOT cloud connections as a simple but reliable protocol conversion and data acquisition platform for the "Connected" industries.`,
        image: imgOem
    }
}

export default function Product() {
    const theme = useTheme();
    const [value, setValue] = useState(0);


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };



    return <div style={{
        paddingTop: '120px',
        width: '100%',
    }}>
        <div style={{
            width: '100%', display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px 0'
        }}>
            <div style={{
                backgroundColor: '#1677ff', width: '250px', height: '70px',
                padding: '0 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px'
            }}
                className="container-diamond"
            >
                <div
                    style={{
                        color: '#fff',
                        fontSize: '30px', fontWeight: 'bold'
                    }}
                >
                    Product
                </div>
            </div>
        </div>


        <Box >
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                // variant="fullWidth"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="full width tabs example"
                TabIndicatorProps={{
                    style: {
                        height: 2,
                        transform: 'translateY(-8px)',
                        borderBottom: '2px solid #1677ff'

                    },
                }}
                sx={{
                    position: 'fixed',
                    top: '84px',
                    // width: '100%',
                    zIndex: 2,
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#fff',
                }}

            >
                {listTab.map((item, index) => {
                    return <Tab key={index} label={item?.title} {...a11yProps(item?.value)} sx={{ color: 'black', fontSize: 14, fontWeight: 700 }} />
                })}
            </Tabs>

            <div
                style={{ backgroundColor: '#fff', position: 'fixed', width: '100%', top: '60px',zIndex:1,paddingTop:'24px' }}
            >
                &nbsp;
            </div>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <HMIOperatorPanel listData={listHmiOperatorPanel} />
                    <Contact />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <HMIOperatorPanel listData={listIndustrialPanelPC} />
                    <Contact />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <HMIOperatorPanel listData={listProtocol} />
                    <Contact />
                </TabPanel>
            </SwipeableViews>
        </Box>
    </div>
}